
import public_health_dashboard from '../Assets/Images/Services/Web GIS/phd.png'
// import orthophoto from '../Assets/Images/Services/Aerial Survey and Mapping/orthophoto.jpg'
// import thermal from '../Assets/Images/Services/Aerial Survey and Mapping/thermal.jpg'
// import spectral_1 from '../Assets/Images/Services/Aerial Survey and Mapping/multisprectal1.jpg'
// import spectral_2 from '../Assets/Images/Services/Aerial Survey and Mapping/multisprectal2.jpg'
import _3d_vector_1 from '../Assets/Images/Services/Aerial Survey and Mapping/3d vector 1.jpg'
import _3d_vector_2 from '../Assets/Images/Services/Aerial Survey and Mapping/3d vector 2.jpg'
import _3d_vector_3 from '../Assets/Images/Services/Aerial Survey and Mapping/3d vector 3.jpg'
import _3d_city_1 from '../Assets/Images/Services/Aerial Survey and Mapping/3D city 1.jpg'
import _3d_city_2 from '../Assets/Images/Services/Aerial Survey and Mapping/3D city 2.jpg'
import _3d_city_3 from '../Assets/Images/Services/Aerial Survey and Mapping/3D city 3.jpg'
// import dem_1 from '../Assets/Images/Services/Aerial Survey and Mapping/DEM.jpg'
// import DSM from '../Assets/Images/Services/Aerial Survey and Mapping/DSM.jpg'

// import landuse from '../Assets/Images/Services/Aerial Survey and Mapping/landuse.jpg'
// import analytical from '../Assets/Images/Services/Aerial Survey and Mapping/analytical mapping.jpg'
import crop from '../Assets/Images/Services/Aerial Survey and Mapping/remote crop monitoring.png'
import ndvi_1 from '../Assets/Images/Services/Aerial Survey and Mapping/ndvi 1.png'
import ndvi_2 from '../Assets/Images/Services/Aerial Survey and Mapping/ndvi 2.png'




import small_survey from '../Assets/Images/Slider/Small/survey.jpg'
import small_planning from '../Assets/Images/Slider/Small/urd.jpg'
import small_software from '../Assets/Images/Slider/Small/software.jpg'
// import small_gis_1 from '../Assets/Images/Slider/Small/gis_1.jpg'
import small_gis_2 from '../Assets/Images/Slider/Small/gis_2.jpg'
import small_capacity from '../Assets/Images/Slider/Small/capacity.jpg'
import small_modeling from '../Assets/Images/Slider/Small/ModelingandSimulation.jpg'
import small_feasibility from '../Assets/Images/Slider/Small/feasibility.jpg'
import small_monitor from '../Assets/Images/Slider/Small/monitor.jpg'


import upd_cover from '../Assets/Images/Services/ServicesNew/Urban Planning/upd_cover.png'
import master_plan_title from '../Assets/Images/Services/ServicesNew/Urban Planning/MasterPlanTitle.jpg'
import master_plan_body from '../Assets/Images/Services/ServicesNew/Urban Planning/MasterPlanBody.jpg'
import mobile_master_plan_body from '../Assets/Images/Services/ServicesNew/Urban Planning/mobile/MasterPlanBody.jpg'

import transport_plan_title from '../Assets/Images/Services/ServicesNew/Urban Planning/transportTitle.jpg'
import transport_plan_body from '../Assets/Images/Services/ServicesNew/Urban Planning/transportBody.jpg'
import mobile_transport_plan_body from '../Assets/Images/Services/ServicesNew/Urban Planning/mobile/transportBody.jpg'

import strategy_plan_title from '../Assets/Images/Services/ServicesNew/Urban Planning/StrategicTitle.jpg'
import strategy_plan_body from '../Assets/Images/Services/ServicesNew/Urban Planning/strategyBody.png'
import mobile_strategy_plan_body from '../Assets/Images/Services/ServicesNew/Urban Planning/mobile/strategyBody.png'

import development_plan_title from '../Assets/Images/Services/ServicesNew/Urban Planning/devTitle.jpg'
import development_plan_body from '../Assets/Images/Services/ServicesNew/Urban Planning/devBody.jpg'
import mobile_development_plan_body from '../Assets/Images/Services/ServicesNew/Urban Planning/mobile/devBody.jpg'

import urban_design_title from '../Assets/Images/Services/ServicesNew/Urban Planning/urbanTitle.jpg'
import urban_design_body_1 from '../Assets/Images/Services/ServicesNew/Urban Planning/urbanBody_1.jpg'
import urban_design_body_2 from '../Assets/Images/Services/ServicesNew/Urban Planning/urbanBody_2.jpg'
import mobile_urban_design_body from '../Assets/Images/Services/ServicesNew/Urban Planning/mobile/urbanBody.jpg'

import survey_cover from '../Assets/Images/Services/ServicesNew/Survey/007-3.jpg'
import photogrammetry_title from '../Assets/Images/Services/ServicesNew/Survey/PhotogrammetryTitle.jpg'
import photogrammetry_body from '../Assets/Images/Services/ServicesNew/Survey/PhotogrammetryBody.mp4'
import remote_sensing_title from '../Assets/Images/Services/ServicesNew/Survey/RemoteSensingtitle.jpg'
import remote_sensing_body from '../Assets/Images/Services/ServicesNew/Survey/Remotesensingbody.png'
import lidar_point from '../Assets/Images/Services/ServicesNew/Survey/Lidarpointcloud.png'
import gis_mapping_title from '../Assets/Images/Services/ServicesNew/Survey/GISMappingTitle.jpg'
import gis_mapping_body from '../Assets/Images/Services/ServicesNew/Survey/GISMappingBody.jpg'
import modelling_title from '../Assets/Images/Services/ServicesNew/Survey/ModelingTitle.jpg'
import modelling_body from '../Assets/Images/Services/ServicesNew/Survey/ModelingBody.png'
import as_built_title from '../Assets/Images/Services/ServicesNew/Survey/AsBuiltSurveyTitle.jpg'
import as_built_body from '../Assets/Images/Services/ServicesNew/Survey/AsBuiltSurveyBody.jpg'


import capacity_cover from '../Assets/Images/Services/ServicesNew/CapacityBuilding/cover.jpg'

import geo_title from '../Assets/Images/Services/ServicesNew/CapacityBuilding/geoTitle.jpg'

import geo_body from '../Assets/Images/Services/ServicesNew/CapacityBuilding/geoBody.jpg'
import plan_ex_title from '../Assets/Images/Services/ServicesNew/CapacityBuilding/plan_ex_title.jpg'
import plan_ex_body from '../Assets/Images/Services/ServicesNew/CapacityBuilding/planExBody.jpg'
import res_lead_title from '../Assets/Images/Services/ServicesNew/CapacityBuilding/responseTitle.jpg'
import res_lead_body from '../Assets/Images/Services/ServicesNew/CapacityBuilding/responseBody.jpeg'


import climate_cover from '../Assets/Images/Services/ServicesNew/Climate/climateCover.jpg'
import assesment_title from '../Assets/Images/Services/ServicesNew/Climate/IntegratedAssessmentTitle.jpg'
import assesment_body from '../Assets/Images/Services/ServicesNew/Climate/IntegratedAssessmentBody.jpg'
import risk_title from '../Assets/Images/Services/ServicesNew/Climate/riskTitle.jpg'
import risk_body from '../Assets/Images/Services/ServicesNew/Climate/riskBody.jpg'
import adaptation_title from '../Assets/Images/Services/ServicesNew/Climate/ClimateAdaptionTitle.jpg'
import adaptation_body from '../Assets/Images/Services/ServicesNew/Climate/climateAdaptionBody.jpg'
import emergency_title from '../Assets/Images/Services/ServicesNew/Climate/emergencyTitle.jpg'
import emergency_body_video from '../Assets/Images/Services/ServicesNew/Climate/EmergencyResponse.mp4'

import modelling_cover from '../Assets/Images/Services/ServicesNew/Modelling/modellingCover.jpg'
import flood_title from '../Assets/Images/Services/ServicesNew/Modelling/flood_title.jpg'
import flood_body from '../Assets/Images/Services/ServicesNew/Modelling/ModelingandSimulation.jpg'
import four_step_title from '../Assets/Images/Services/ServicesNew/Modelling/fourstep_title.jpg'
import fourstep_body from '../Assets/Images/Services/ServicesNew/Modelling/fourstepBody.png'
import traffic_count from '../Assets/Images/Services/ServicesNew/Modelling/trafficCount.mp4'
import transportation from '../Assets/Images/Services/ServicesNew/Modelling/TransportModellingandSimulation.mp4'


import feasibility_cover from '../Assets/Images/Services/ServicesNew/Feasibility/feasibility_cover.jpg'
import LandAcquisitionTitle from '../Assets/Images/Services/ServicesNew/Feasibility/LandAcquisitionTitle.jpg'
import LandAcquisitionBody from '../Assets/Images/Services/ServicesNew/Feasibility/LandAcquisitionBody.mp4'
import hydroTitle from '../Assets/Images/Services/ServicesNew/Feasibility/HydrologicalAnalysisTitle.jpg'
import hydroBody from '../Assets/Images/Services/ServicesNew/Feasibility/HydrologicalAnalysisBody.jpg'
import environmentTitle from '../Assets/Images/Services/ServicesNew/Feasibility/EnvironmentalAnalysisTitle.jpg'
import environmentBody from '../Assets/Images/Services/ServicesNew/Feasibility/EnvironmentalAnalysisBody.jpg'


import pnd_icon from '../Assets/Icons/services/UrbanPlannign.svg'
import soft_icon from '../Assets/Icons/services/Softnapp.svg'
import geo_icon from '../Assets/Icons/services/GISAPP.svg'
import climate_icon from '../Assets/Icons/services/Climate.svg'

import survey_icon from '../Assets/Icons/services/Survey.svg'

import model_icon from '../Assets/Icons/services/ModnSim.svg'
import monitoring_icon from '../Assets/Icons/services/MonnEvo.svg'
import feasibility_icon from '../Assets/Icons/services/Feasiblity.svg'
import SoftwareService from '../Components/Service_Layouts/SoftwareService'



const createnewobject = ({ title, titleImage = null, bodyImage = null, desc = null, bodyVideo = null, color, single_image, mobile_body }) => {
    return {
        title: title, titleImage: titleImage, bodyImage: bodyImage, description: desc,
        bodyVideo: bodyVideo, color: color, single_image: single_image, mobile_body: mobile_body
    }
}



export const servicesArray = [
    {
        "title": "Urban Planning and Design",
        "description": "Tiller continues to be a pioneer in urban design, consistently delivering master plans that prioritize innovation and adaptability. By focusing on climate resilience, inclusivity, and smart, sustainable practices, Tiller envisions urban spaces that not only withstand current challenges but also anticipate and meet the evolving needs of the future. This commitment to dynamic and forward-thinking design positions Tiller at the forefront of creating cities that are not only resilient and efficient but also welcoming and responsive to the diverse needs of their inhabitants.",
        "id": 1,
        icon: pnd_icon,
        url: "/",
        "small_photo": small_planning,
        cover_image: upd_cover,
        mobile_cover_image: null,

        order: 1,
        list: [{
            ...createnewobject({ title: 'Master Plan and Detailed design', titleImage: master_plan_title, bodyImage: master_plan_body, mobile_body: mobile_master_plan_body }),
            sub: [
                { ...createnewobject({ title: 'Preparation of Structure Plan, Urban Area Plan, Landuse Plan and Detailed Area Plan' }) },
                { ...createnewobject({ title: 'Integrated planning approach and Nature-based solutions' }) },
                { ...createnewobject({ title: 'Vision-driven, Climate resilient and People centric plan' }) },
                { ...createnewobject({ title: 'Housing/New Township Masterplan' }) },
            ]

        }, {
            ...createnewobject({ title: 'Transport Planning',mobile_body:mobile_transport_plan_body, titleImage: transport_plan_title, bodyImage: transport_plan_body, color: '#ED7D31' }),
            sub: [
                {
                    ...createnewobject({ title: 'City Level Transport Planning', }),
                    sub: [{
                        ...createnewobject({ title: 'Traffic Demand Calculation', }),
                        ...createnewobject({ title: 'Mode Choice behavior', }),
                        ...createnewobject({ title: 'Origin and Destination (Generation and Distribution)', }),
                        ...createnewobject({ title: 'Trip Assignment/Proposal', }),

                    }]
                },
                {
                    ...createnewobject({ title: 'Highway Road Design', }),
                    sub: [{
                        ...createnewobject({ title: 'AAT (Annual Average Traffic) Count', }),
                        ...createnewobject({ title: 'Peak hour traffic volume', }),
                        ...createnewobject({ title: 'Pavement Design', }),
                        ...createnewobject({ title: 'Proper Road Signage', }),
                        ...createnewobject({ title: 'Road Geometry and Speed Design', }),
                        ...createnewobject({ title: 'Road Safety Audit', }),

                    }]
                },
            ]
        },
        {
            ...createnewobject({ title: 'Strategic Planning',mobile_body:mobile_strategy_plan_body, titleImage: strategy_plan_title, bodyImage: strategy_plan_body }),
            sub: [{
                ...createnewobject({ title: 'Data Driven policy making', }),
                ...createnewobject({ title: 'Social, Economic and Spatial Inclusion', }),
                ...createnewobject({ title: 'Potential Future Growth and Development scenario evaluation', }),
            }]
        },
        {
            ...createnewobject({ title: 'Development Management',mobile_body:mobile_development_plan_body, titleImage: development_plan_title, bodyImage: development_plan_body }),
            sub: [{
                ...createnewobject({ title: 'Zoning & Development Regulations', }),
                ...createnewobject({ title: 'Landuse control (Permitted, conditionally permitted, Not permitted)', }),
                ...createnewobject({ title: 'No development zone delineation', }),
                ...createnewobject({ title: 'Growth Management (Fast growth zone, slow growth zone delineation)', }),
            }]
        },
        {
            ...createnewobject({ title: 'Urban Design',mobile_body:mobile_urban_design_body, titleImage: urban_design_title, bodyImage: [ urban_design_body_2,urban_design_body_1,], }),
            sub: [{
                ...createnewobject({ title: 'Green Placemaking/Public Space Design', }),
                ...createnewobject({ title: 'Green and Blue Network', }),
            }]
        },],

        "related_projects": [1, 2, 3, 4, 5, 6, 15, 17, 18, 21, 22],

    },
    {
        "title": "Software and Application Development",
        order: 2,
        display_component: <SoftwareService />,
        "small_photo": small_software,
        icon: soft_icon, url: "/",
        "description": "At Tiller, we excel in Software and Application Development, pioneering solutions that seamlessly integrate technology into diverse sectors. We create easy-to-adopt web solutions to meet the unique needs of our clients. From conceptualization to deployment, we are dedicated to delivering user-friendly, scalable, and cutting-edge solutions that drive efficiency and success in the digital realm.",
        "id": 2,
        "related_projects": [
            7, 8, 25, 24
        ],
    },
    {
        "title": "Modelling and Simulation",
        order: 6,
        "small_photo": small_modeling,
        cover_image: modelling_cover,
        mobile_cover_image: null,
        icon: model_icon, url: "/",
        "description": "Tiller uses Artificial Intelligence (AI) technologies for the analyzing, modelling, and predicting of transport flows with a very high accuracy. This advantage comes from combining the technical knowledge of predictive analytics with hands-on expertise in transport planning. In resource and environmental analysis, it uses analytical tools like water modelling, and climate modelling to usher the right solution to the forefront.",
        "id": 3,
        "related_projects": [7, 21],
        list: [
            {
                ...createnewobject({ title: 'Flood Modelling', titleImage: flood_title, bodyImage: flood_body }),
                sub: [
                    { ...createnewobject({ title: 'Flood modelling evaluates various return periods. These results assist urban planners, engineers, and decision-makers in determining appropriate development measures. The outcomes of hydrological modelling serve as the foundation for cost-benefit analyses crucial in preparing Development Project Proposals (DPPs) for infrastructure projects.', }) }
                ]
            },
            {
                ...createnewobject({ title: 'Transport Modelling and Simulation', bodyVideo: transportation }), sub: [
                    { ...createnewobject({ title: 'Tiller employs AI for streamlined traffic management, reducing congestion and enhancing urban mobility for an improved city life.', }) }
                ]
            },
            {
                ...createnewobject({ title: 'Four Step Modelling', titleImage: four_step_title, bodyImage: fourstep_body, }),
                sub: [{ ...createnewobject({ title: 'Analyzing and predicting urban transportation patterns through trip generation, distribution, mode choice, and route assignment for effective city planning.', }) }]
            },
            {
                ...createnewobject({ title: 'Traffic Count Automation', bodyVideo: traffic_count, }),
                sub: [
                    { ...createnewobject({ title: 'Tiller employs AI for streamlined traffic management, reducing congestion and enhancing urban mobility for an improved city life.', }) }
                ]
            },
        ]
    },
    {
        "title": "Feasibility and Detailed Design",
        "description": "Tiller carries out feasibility studies and detailed design solutions to establish the suitability of specific sites to accommodate client’s precise development requirements. Our passion for design is expressed through hand drawing and illustration alongside innovative digital tools to provide innovate and reimagined places.",
        order: 7,
        "id": 4,

        icon: feasibility_icon, url: "/",
        "related_projects": [
            9,
            10
        ],

        "small_photo": small_feasibility,
        cover_image: feasibility_cover,
        mobile_cover_image: null,
        list: [
            {
                ...createnewobject({ title: 'Land Acquisition & Resettlement Plan', 
                titleImage: LandAcquisitionTitle, bodyVideo: LandAcquisitionBody }),
                sub: [
                    {
                        ...createnewobject({ title: 'A Land Acquisition and Resettlement Plan (LARP) outlines how land is acquired and communities are resettled during development projects, addressing legal procedures, compensation, and social impact assessment.' })
                    }]
            },
            {
                ...createnewobject({ title: 'Hydrological Analysis', titleImage: hydroTitle, bodyImage: hydroBody }), sub: [
                    {
                        ...createnewobject({ title: 'Hydrological analysis studies water distribution, movement, and properties to understand patterns and support water management and infrastructure planning.' })
                    }]
            },
            {
                ...createnewobject({ title: 'Environmental Analysis', titleImage: environmentTitle, bodyImage: environmentBody }), sub: [
                    {
                        ...createnewobject({ title: 'Environmental analysis assesses factors like air and water quality, biodiversity, and land use to understand their impact on human activities, informing decision-making in areas like urban planning and natural resource management.' })
                    }]
            },
        ]
    },
    {
        "title": "Climate Change Adaption and Disaster Risk Reduction",
        "description": "Tiller provides integrated assessments, including loss and damage, carbon emission, and multi-hazard risk assessments. It utilizes GIS-based mapping and data analysis to prioritize disaster risk interventions. The service promotes ecosystem-based and community-based adaptation strategies and climate-resilient planning while enhancing capacity building and emergency response planning through training, plan updates, and drills, ensuring communities are better equipped to handle climate change impacts.",
        "id": 5,
        order: 5,
        icon: climate_icon, url: "/",
        "related_projects": [2, 20],
        "small_photo": small_monitor,
        cover_image: climate_cover,
        mobile_cover_image: null,

        list: [
            {
                ...createnewobject({ title: 'Integrated Assessments', titleImage: assesment_title, bodyImage: assesment_body, }), sub: [
                    { ...createnewobject({ title: 'Conducting Loss and Damage Assessments', }) },
                    { ...createnewobject({ title: 'Carbon Emission Assessments', }) },
                    { ...createnewobject({ title: 'Multi-Hazard Risk Assessment', }) }
                ]
            },
            {
                ...createnewobject({ title: 'Risk Mapping and Analysis', titleImage: risk_title, bodyImage: risk_body }), sub: [
                    { ...createnewobject({ title: 'Developing GIS-based maps to identify and assess disaster risks', }) },
                    { ...createnewobject({ title: 'Analyzing data to prioritize areas for intervention and adaptation strategies.', }) }
                ]
            },
            {
                ...createnewobject({ title: 'Climate Change Adaptation Strategies', titleImage: adaptation_title, bodyImage: adaptation_body }), sub: [
                    { ...createnewobject({ title: 'Ecosystem-based Adaptation', }) },
                    { ...createnewobject({ title: 'Community-Based Adaptation Strategies', }) }
                ]
            },
            {
                ...createnewobject({
                    title: 'Capacity Building & Emergency Response Planning', titleImage: emergency_title,
                    bodyVideo: emergency_body_video
                }), sub: [
                    { ...createnewobject({ title: 'Training local government officials, community leaders, and residents on CCA and DRR', }) },
                    { ...createnewobject({ title: 'Developing and updating emergency response plans.', }) },
                    { ...createnewobject({ title: 'Conducting drills and exercises to test response capabilities.', }) }
                ]
            },

        ]
    },
    {
        "title": "Web GIS Application Development",
        "description": "Tiller has a unique capability to unveil deeper insights into data such as trends, patterns, relationships and situations, empowering our clients to make smarter decisions. The distinctive capability of Tiller extends to providing applications such as web mapping, realistic visual representations, advanced spatial analysis, and app based real time data collaboration.",
        "id": 6,
        order: 4,
        icon: geo_icon, url: "/",
        "related_projects": [],
        "small_photo": small_gis_2,
        list: [
            { ...createnewobject({ title: 'Municipal Asset Management System', }), },
            { ...createnewobject({ title: 'Municipal Service Delivery (Smart Bin)', }), },
            { ...createnewobject({ title: 'Public Health Dashboard', titleImage: [public_health_dashboard] }), },
            { ...createnewobject({ title: 'Map Service', }), },
            { ...createnewobject({ title: 'Location Intelligence Service', }), },
            { ...createnewobject({ title: 'Planning Information System', }), },
            { ...createnewobject({ title: 'Land Information System', }), },
            { ...createnewobject({ title: 'Web based (IoT, RS) Trendline, change and comparison [air quality, water quality, temperature, vegetation, built-up space, river shifting, Agriculture] (GIS Application Development)', }), },

        ]
    },
    {
        "title": "Survey, GIS Mapping and Remote Sensing",
        "sub": "(UAV/Drone/Aerial)",
        "description": "Tiller excels in conducting aerial surveys, showcasing unparalleled technical expertise that distinguishes it from competitors. Going beyond UAV-based surveying, Tiller introduces groundbreaking LiDAR Technology in Bangladesh. The recent inclusion of the Fixed-wing JOUAV professional mapping UAV further solidifies Tiller's commitment to cutting-edge aerial surveying, enhancing its inventory with advanced capabilities in capturing precise and comprehensive data for diverse applications.",
        "id": 7,
        icon: survey_icon, url: "/",
        order: 3,
        "related_projects": [
            3, 11, 12, 13, 14, 22, 23,
        ],

        "small_photo": small_survey,
        cover_image: survey_cover,
        mobile_cover_image: null,
        list: [
            {
                ...createnewobject({ title: 'Photogrammetry', titleImage: photogrammetry_title, bodyVideo: photogrammetry_body, }), sub: [
                    { ...createnewobject({ title: 'Aerial Image', }), },
                    { ...createnewobject({ title: 'Orthophoto', }) },
                    { ...createnewobject({ title: 'Thermal Map', }), },
                    { ...createnewobject({ title: '3D vector Map', }) }
                ]
            },
            {
                ...createnewobject({ title: 'Remote Sensing', titleImage: remote_sensing_title, bodyImage: remote_sensing_body, }),
                type: "image-comparator", sub: [
                    { ...createnewobject({ title: 'Crop Monitoring, Healthy Vegetation & Forest Map', titleImage: [crop] }) },
                    { ...createnewobject({ title: 'LULC, NDVI, NDWI, LST analysis', titleImage: [ndvi_1, ndvi_2], }) },
                    { ...createnewobject({ title: 'High Resolution Multi Spectral Image', }) }

                ]
            }, {
                ...createnewobject({ title: 'LiDAR Point Cloud', single_image: lidar_point }), type: "single-photo", sub: [
                    { ...createnewobject({ title: 'Light Detection and Ranging (LiDAR) is a remote sensing technique that light from a laser to measure out the Elevation like buildings, ground and forest. LiDAR uses ultraviolet, visible or near-infrared sources to sense objects.', }) }
                ]
            },
            {
                ...createnewobject({ title: 'GIS Mapping', titleImage: gis_mapping_title, bodyImage: gis_mapping_body }), sub: [
                    { ...createnewobject({ title: 'Utility Mapping (Electricity network, Water supply network, Drainage and Sewerage Network)', }) },
                    { ...createnewobject({ title: 'Physical feature mapping', }) },
                    { ...createnewobject({ title: 'Landuse Mapping', }) },
                    { ...createnewobject({ title: 'Cadastral mapping', }) },
                    { ...createnewobject({ title: 'Contour Mapping', }) },
                    { ...createnewobject({ title: 'Analytical Mapping (Spatial Analysis, Network Analysis, Geoprocessing)', }) },
                    { ...createnewobject({ title: 'Floodplain Mapping', }) },
                ]
            }, {
                ...createnewobject({ title: 'Modelling', titleImage: modelling_title, bodyImage: modelling_body, }),
                sub: [{ ...createnewobject({ title: 'Digital Elevation/Terrain Model (DEM, DTM)', }) },
                { ...createnewobject({ title: '3D city Model', }) }]
            }, {
                ...createnewobject({ title: 'As Built Survey', titleImage: as_built_title, bodyImage: as_built_body }), sub: [
                    {
                        ...createnewobject({
                            title: `The as-built survey typically takes place during or near the 
                    end of the project. A professional land surveyor comes onto the construction site 
                    to record existing conditions and verify the building or structure are laid out
                     consistently with the architectural or engineering drawings.`,
                        })
                    }
                ]
            }
        ]
    },
    {
        "title": "Capacity Building",
        order: 8,
        "description": "Tiller is collaborating with various central and local government institutions (LGIs) and universities in Bangladesh to enhance the capabilities of stakeholders engaged in decision-making processes. Specifically, we are providing practical, hands-on knowledge in GIS applications to local government officials responsible for issuing land use clearance certificates through tailored programs, training sessions, and workshops.",
        "id": 8,
        icon: monitoring_icon, url: "/",
        "related_projects": [
            25
        ],
        "small_photo": small_capacity,
        cover_image: capacity_cover,
        mobile_cover_image: null,
        list: [
            {
                ...createnewobject({ title: 'Geospatial Tech', titleImage: geo_title, bodyImage: geo_body }), sub: [
                    { ...createnewobject({ title: 'Capacity Building in Advance Surveying Technologies (RTK)', }) },
                    { ...createnewobject({ title: 'GIS Mapping', }) },
                    { ...createnewobject({ title: 'Photogrammetry, UAV Survey, LiDAR, AI', }) }
                ]
            },
            {
                ...createnewobject({ title: 'Planning Excellence', titleImage: plan_ex_title, bodyImage: plan_ex_body }), sub: [
                    { ...createnewobject({ title: 'Plan Preparation', }) },
                    { ...createnewobject({ title: 'Layout plan', }) },
                    { ...createnewobject({ title: 'Planning Rules and Regulations', }) }
                ]
            },
            {
                ...createnewobject({ title: 'Response & Leadership', titleImage: res_lead_title, bodyImage: res_lead_body }), sub: [
                    { ...createnewobject({ title: 'Emergency Response Plan', }) },
                    { ...createnewobject({ title: 'Leadership Management', }) }
                ]
            },

        ]
    }
]

